@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  background-color: $color-black;
  color: $color-white;
  display: block;
  height: $offerbar-mobile-height;
  min-height: $offerbar-mobile-height;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  @include breakpoint($landscape-up) {
    height: $offerbar-height;
    min-height: $offerbar-height;
    overflow: hidden;
  }
  &__height-option {
    &.header-offers-banner-formatter {
      height: $offerbar-opt-height;
      min-height: $offerbar-opt-height;
    }
  }
  .gnav-offers-hidden & {
    display: none;
    height: 0;
    min-height: 0;
  }
  &__carousel {
    position: relative;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    padding-#{$ldirection}: 5px;
    padding-#{$rdirection}: 30px;
    @include breakpoint($small-down) {
      justify-content: start;
    }
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 44px;
      padding-#{$rdirection}: 44px;
    }
    &-item {
      display: none;
      .not-initialized &:first-of-type,
      &.item-active {
        display: block;
      }
      .fade & {
        animation-name: fade;
        animation-duration: 1.5s;
      }
    }
    &-arrow {
      align-content: center;
      .icon {
        fill: inherit;
        width: 22px;
        height: 22px;
        margin: -2px 14px 0;
      }
    }
  }
  a {
    color: $color-white;
  }
  .header-offers-banner-item a {
    color: inherit;
  }
  &__close {
    cursor: pointer;
    display: block;
    position: absolute;
    #{$rdirection}: 17px;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint($landscape-up) {
      #{$rdirection}: 23px;
    }
    .icon {
      fill: inherit;
      height: 32px;
      width: 32px;
      @include breakpoint($landscape-up) {
        height: 28px;
        width: 28px;
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
